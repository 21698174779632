<template src="./CreateRecommended.html"></template>

<script>
import { putFile } from "@/services/files";
import courseService from "../../services/Courses";

export default {
  name: "CreateRecommended",
  data() {
    return {
      infoRecommendation: {},
      imageFile: null,
    };
  },
  methods: {
    deleteFile() {
      this.imageFile = null;
    },
    async saveRecommended() {
      this.$store.dispatch("fetchLoading", true);
      let urlImage = null;
      if (this.imageFile) {
        try {
          urlImage = await putFile(
            this.imageFile,
            "recommendedCourses",
            `${this.imageFile.name}`
          );
          this.infoRecommendation.foto = urlImage;
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo",
            position: "is-bottom",
            type: "is-danger",
          });
        }
      }
      try {
        await courseService.createRecommended(this.infoRecommendation);
        this.$store.dispatch("fetchLoading", false);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Recomendación creada",
          position: "is-bottom",
          type: "is-success",
        });
        this.$router.push({ path: "academico" }).catch(() => {});
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: createRecommended/saveRecommended()", e);
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./CreateRecommended.scss"></style>